<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.form.full_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.admin_user_id" disabled label="Rec Id" outlined></v-text-field>                                                                
                                <v-text-field v-model="form.user_login_id" label="Login ID" :rules="inputRules" outlined readonly></v-text-field>
                                <v-text-field v-model="form.password" label="Password" type="password" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.full_name" label="Full Name" :rules="inputRules" outlined></v-text-field>
                                <v-select
                                    v-model = "form.user_type"
                                    :items="userTypeList"
                                    item-text="description"
                                    item-value="code"
                                    label="User Type"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                ></v-select>                                
                                <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            dense
                                            :value="form.start_date"
                                            label="Start Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                                </v-menu>
                                <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            dense
                                            :value="form.end_date"
                                            label="End Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                                </v-menu>                                

                                <v-select
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                ></v-select>
                                <v-btn dark color="cbtnSave" @click="save">Save</v-btn>                                
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>

            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-toolbar dark color="panelSysInfo"><v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="info in systemInfo" :key="info.alias" :value="form[info.name]" disabled :label="info.alias" outlined></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card>

                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApUserService from "@/services/ApUserService"
import ApParameterService from "@/services/ApParameterService"
import moment from 'moment'
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Notification',disabled: false,href:'/notification/',},
              {text:'Setting',disabled: false,href:'/Notification/setttings',},
              {text:'Details',disabled: false,href:'#',},
              ],
            status_list:[],
            userTypeList:[],
            routeParam:[],
            form:{login_id:null,admin_user_id:0},
            systemInfo:[
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],

            logo:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},

    }
  },
  async created() {
    this.routeParam['admin_user_id'] = this.$route.params.admin_user_id;
    this.getParameter();
    this.viewDetail();


  },
  
  methods:{

    async viewDetail(){
        this.form.admin_user_id = this.routeParam.admin_user_id
        try{
            ApUserService.view_user(this.form).then((res) => {
                //console.log(res.data.data);
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                if(this.form.start_date == "0000-00-00" || !this.form.start_date)
                    this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
                
                if(this.form.end_date == "0000-00-00" || !this.form.end_date)
                    this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');

                this.status_list = res.data.statusList; 
               
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View User',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        try{
            ApUserService.update_user(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','Update User','Success.');
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Update User',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },
    async getParameter(){
            try{
                let parameter = {login_id:"",param_type:"add-admin_user"}
                ApParameterService.get_parameter(parameter).then((res) => {
                    this.userTypeList = res.data.userTypeList;
                    //console.log(res.data);
                    
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        },
  }
}
</script>